import { jsx as C, jsxs as z, Fragment as Pn } from "react/jsx-runtime";
import * as u from "react";
import me, { useMemo as qe } from "react";
import { e as xn, a as en, u as ae, i as Rn, s as _ } from "../../style.module-ef3fb15b.js";
import { P as e, g as le, a as ce, s as Y, e as R, b as ue, _ as K, f as Q, h as de, r as nn, n as He, o as Nn } from "../../styled-832b6886.js";
import { u as Le } from "../../useTheme-05894948.js";
import { c as Oe, T as tn, r as on, g as xe, e as On } from "../../utils-6be14a1f.js";
import { u as $e, o as J, a as ne, b as Cn, s as Ue } from "../../useTimeout-890b47bd.js";
import { o as ve, u as ze, c as Ke, d as wn } from "../../useEventCallback-a69e3560.js";
import * as kn from "react-dom";
function Mn(n) {
  const {
    prototype: t = {}
  } = n;
  return !!t.isReactComponent;
}
function rn(n, t, o, r, a) {
  const s = n[t], i = a || t;
  if (s == null || // When server-side rendering React doesn't warn either.
  // This is not an accurate check for SSR.
  // This is only in place for Emotion compat.
  // TODO: Revisit once https://github.com/facebook/react/issues/20047 is resolved.
  typeof window > "u")
    return null;
  let f;
  const h = s.type;
  return typeof h == "function" && !Mn(h) && (f = "Did you accidentally use a plain function component for an element instead?"), f !== void 0 ? new Error(`Invalid ${r} \`${i}\` supplied to \`${o}\`. Expected an element that can hold a ref. ${f} For more information see https://mui.com/r/caveat-with-refs-guide`) : null;
}
const sn = Oe(e.element, rn);
sn.isRequired = Oe(e.element.isRequired, rn);
const Ce = sn;
function ye(n, t, o, r, a) {
  if (process.env.NODE_ENV === "production")
    return null;
  const s = n[t], i = a || t;
  return s == null ? null : s && s.nodeType !== 1 ? new Error(`Invalid ${r} \`${i}\` supplied to \`${o}\`. Expected an HTMLElement.`) : null;
}
const Sn = e.oneOfType([e.func, e.object]), In = Sn;
function an(n) {
  const t = n.documentElement.clientWidth;
  return Math.abs(window.innerWidth - t);
}
function $n(n) {
  const t = typeof n;
  switch (t) {
    case "number":
      return Number.isNaN(n) ? "NaN" : Number.isFinite(n) ? n !== Math.floor(n) ? "float" : "number" : "Infinity";
    case "object":
      return n === null ? "null" : n.constructor.name;
    default:
      return t;
  }
}
function Dn(n) {
  return typeof n == "number" && isFinite(n) && Math.floor(n) === n;
}
const Fn = Number.isInteger || Dn;
function ln(n, t, o, r) {
  const a = n[t];
  if (a == null || !Fn(a)) {
    const s = $n(a);
    return new RangeError(`Invalid ${r} \`${t}\` of type \`${s}\` supplied to \`${o}\`, expected \`integer\`.`);
  }
  return null;
}
function cn(n, t, ...o) {
  return n[t] === void 0 ? null : ln(n, t, ...o);
}
function De() {
  return null;
}
cn.isRequired = ln;
De.isRequired = De;
const un = process.env.NODE_ENV === "production" ? De : cn, Ln = /* @__PURE__ */ u.createContext();
process.env.NODE_ENV !== "production" && (e.node, e.bool);
const _n = () => {
  const n = u.useContext(Ln);
  return n ?? !1;
}, dn = /* @__PURE__ */ u.createContext({});
process.env.NODE_ENV !== "production" && (dn.displayName = "ListContext");
const jn = dn;
function An(n) {
  return le("MuiList", n);
}
ce("MuiList", ["root", "padding", "dense", "subheader"]);
const Bn = ["children", "className", "component", "dense", "disablePadding", "subheader"], Vn = (n) => {
  const {
    classes: t,
    disablePadding: o,
    dense: r,
    subheader: a
  } = n;
  return de({
    root: ["root", !o && "padding", r && "dense", a && "subheader"]
  }, An, t);
}, qn = Y("ul", {
  name: "MuiList",
  slot: "Root",
  overridesResolver: (n, t) => {
    const {
      ownerState: o
    } = n;
    return [t.root, !o.disablePadding && t.padding, o.dense && t.dense, o.subheader && t.subheader];
  }
})(({
  ownerState: n
}) => R({
  listStyle: "none",
  margin: 0,
  padding: 0,
  position: "relative"
}, !n.disablePadding && {
  paddingTop: 8,
  paddingBottom: 8
}, n.subheader && {
  paddingTop: 0
})), pn = /* @__PURE__ */ u.forwardRef(function(t, o) {
  const r = ue({
    props: t,
    name: "MuiList"
  }), {
    children: a,
    className: s,
    component: i = "ul",
    dense: f = !1,
    disablePadding: h = !1,
    subheader: g
  } = r, E = K(r, Bn), y = u.useMemo(() => ({
    dense: f
  }), [f]), k = R({}, r, {
    component: i,
    dense: f,
    disablePadding: h
  }), N = Vn(k);
  return /* @__PURE__ */ C(jn.Provider, {
    value: y,
    children: /* @__PURE__ */ z(qn, R({
      as: i,
      className: Q(N.root, s),
      ref: o,
      ownerState: k
    }, E, {
      children: [g, a]
    }))
  });
});
process.env.NODE_ENV !== "production" && (pn.propTypes = {
  // ┌────────────────────────────── Warning ──────────────────────────────┐
  // │ These PropTypes are generated from the TypeScript type definitions. │
  // │    To update them, edit the d.ts file and run `pnpm proptypes`.     │
  // └─────────────────────────────────────────────────────────────────────┘
  /**
   * The content of the component.
   */
  children: e.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: e.object,
  /**
   * @ignore
   */
  className: e.string,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: e.elementType,
  /**
   * If `true`, compact vertical padding designed for keyboard and mouse input is used for
   * the list and list items.
   * The prop is available to descendant components as the `dense` context.
   * @default false
   */
  dense: e.bool,
  /**
   * If `true`, vertical padding is removed from the list.
   * @default false
   */
  disablePadding: e.bool,
  /**
   * The content of the subheader, normally `ListSubheader`.
   */
  subheader: e.node,
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: e.oneOfType([e.arrayOf(e.oneOfType([e.func, e.object, e.bool])), e.func, e.object])
});
const Hn = pn, Un = ["actions", "autoFocus", "autoFocusItem", "children", "className", "disabledItemsFocusable", "disableListWrap", "onKeyDown", "variant"];
function Me(n, t, o) {
  return n === t ? n.firstChild : t && t.nextElementSibling ? t.nextElementSibling : o ? null : n.firstChild;
}
function We(n, t, o) {
  return n === t ? o ? n.firstChild : n.lastChild : t && t.previousElementSibling ? t.previousElementSibling : o ? null : n.lastChild;
}
function fn(n, t) {
  if (t === void 0)
    return !0;
  let o = n.innerText;
  return o === void 0 && (o = n.textContent), o = o.trim().toLowerCase(), o.length === 0 ? !1 : t.repeating ? o[0] === t.keys[0] : o.indexOf(t.keys.join("")) === 0;
}
function be(n, t, o, r, a, s) {
  let i = !1, f = a(n, t, t ? o : !1);
  for (; f; ) {
    if (f === n.firstChild) {
      if (i)
        return !1;
      i = !0;
    }
    const h = r ? !1 : f.disabled || f.getAttribute("aria-disabled") === "true";
    if (!f.hasAttribute("tabindex") || !fn(f, s) || h)
      f = a(n, f, o);
    else
      return f.focus(), !0;
  }
  return !1;
}
const hn = /* @__PURE__ */ u.forwardRef(function(t, o) {
  const {
    // private
    // eslint-disable-next-line react/prop-types
    actions: r,
    autoFocus: a = !1,
    autoFocusItem: s = !1,
    children: i,
    className: f,
    disabledItemsFocusable: h = !1,
    disableListWrap: g = !1,
    onKeyDown: E,
    variant: y = "selectedMenu"
  } = t, k = K(t, Un), N = u.useRef(null), T = u.useRef({
    keys: [],
    repeating: !0,
    previousKeyMatched: !0,
    lastTime: null
  });
  $e(() => {
    a && N.current.focus();
  }, [a]), u.useImperativeHandle(r, () => ({
    adjustStyleForScrollbar: (c, {
      direction: m
    }) => {
      const v = !N.current.style.width;
      if (c.clientHeight < N.current.clientHeight && v) {
        const w = `${an(J(c))}px`;
        N.current.style[m === "rtl" ? "paddingLeft" : "paddingRight"] = w, N.current.style.width = `calc(100% + ${w})`;
      }
      return N.current;
    }
  }), []);
  const l = (c) => {
    const m = N.current, v = c.key, w = J(m).activeElement;
    if (v === "ArrowDown")
      c.preventDefault(), be(m, w, g, h, Me);
    else if (v === "ArrowUp")
      c.preventDefault(), be(m, w, g, h, We);
    else if (v === "Home")
      c.preventDefault(), be(m, null, g, h, Me);
    else if (v === "End")
      c.preventDefault(), be(m, null, g, h, We);
    else if (v.length === 1) {
      const b = T.current, D = v.toLowerCase(), A = performance.now();
      b.keys.length > 0 && (A - b.lastTime > 500 ? (b.keys = [], b.repeating = !0, b.previousKeyMatched = !0) : b.repeating && D !== b.keys[0] && (b.repeating = !1)), b.lastTime = A, b.keys.push(D);
      const L = w && !b.repeating && fn(w, b);
      b.previousKeyMatched && (L || be(m, w, !1, h, Me, b)) ? c.preventDefault() : b.previousKeyMatched = !1;
    }
    E && E(c);
  }, d = ne(N, o);
  let p = -1;
  u.Children.forEach(i, (c, m) => {
    if (!/* @__PURE__ */ u.isValidElement(c)) {
      p === m && (p += 1, p >= i.length && (p = -1));
      return;
    }
    process.env.NODE_ENV !== "production" && nn.isFragment(c) && console.error(["MUI: The Menu component doesn't accept a Fragment as a child.", "Consider providing an array instead."].join(`
`)), c.props.disabled || (y === "selectedMenu" && c.props.selected || p === -1) && (p = m), p === m && (c.props.disabled || c.props.muiSkipListHighlight || c.type.muiSkipListHighlight) && (p += 1, p >= i.length && (p = -1));
  });
  const S = u.Children.map(i, (c, m) => {
    if (m === p) {
      const v = {};
      return s && (v.autoFocus = !0), c.props.tabIndex === void 0 && y === "selectedMenu" && (v.tabIndex = 0), /* @__PURE__ */ u.cloneElement(c, v);
    }
    return c;
  });
  return /* @__PURE__ */ C(Hn, R({
    role: "menu",
    ref: d,
    className: f,
    onKeyDown: l,
    tabIndex: a ? 0 : -1
  }, k, {
    children: S
  }));
});
process.env.NODE_ENV !== "production" && (hn.propTypes = {
  // ┌────────────────────────────── Warning ──────────────────────────────┐
  // │ These PropTypes are generated from the TypeScript type definitions. │
  // │    To update them, edit the d.ts file and run `pnpm proptypes`.     │
  // └─────────────────────────────────────────────────────────────────────┘
  /**
   * If `true`, will focus the `[role="menu"]` container and move into tab order.
   * @default false
   */
  autoFocus: e.bool,
  /**
   * If `true`, will focus the first menuitem if `variant="menu"` or selected item
   * if `variant="selectedMenu"`.
   * @default false
   */
  autoFocusItem: e.bool,
  /**
   * MenuList contents, normally `MenuItem`s.
   */
  children: e.node,
  /**
   * @ignore
   */
  className: e.string,
  /**
   * If `true`, will allow focus on disabled items.
   * @default false
   */
  disabledItemsFocusable: e.bool,
  /**
   * If `true`, the menu items will not wrap focus.
   * @default false
   */
  disableListWrap: e.bool,
  /**
   * @ignore
   */
  onKeyDown: e.func,
  /**
   * The variant to use. Use `menu` to prevent selected items from impacting the initial focus
   * and the vertical alignment relative to the anchor element.
   * @default 'selectedMenu'
   */
  variant: e.oneOf(["menu", "selectedMenu"])
});
const zn = hn, Kn = ["addEndListener", "appear", "children", "easing", "in", "onEnter", "onEntered", "onEntering", "onExit", "onExited", "onExiting", "style", "timeout", "TransitionComponent"];
function Fe(n) {
  return `scale(${n}, ${n ** 2})`;
}
const Wn = {
  entering: {
    opacity: 1,
    transform: Fe(1)
  },
  entered: {
    opacity: 1,
    transform: "none"
  }
}, Se = typeof navigator < "u" && /^((?!chrome|android).)*(safari|mobile)/i.test(navigator.userAgent) && /(os |version\/)15(.|_)4/i.test(navigator.userAgent), _e = /* @__PURE__ */ u.forwardRef(function(t, o) {
  const {
    addEndListener: r,
    appear: a = !0,
    children: s,
    easing: i,
    in: f,
    onEnter: h,
    onEntered: g,
    onEntering: E,
    onExit: y,
    onExited: k,
    onExiting: N,
    style: T,
    timeout: l = "auto",
    // eslint-disable-next-line react/prop-types
    TransitionComponent: d = tn
  } = t, p = K(t, Kn), S = Cn(), c = u.useRef(), m = Le(), v = u.useRef(null), w = ne(v, s.ref, o), b = (P) => (x) => {
    if (P) {
      const O = v.current;
      x === void 0 ? P(O) : P(O, x);
    }
  }, D = b(E), A = b((P, x) => {
    on(P);
    const {
      duration: O,
      delay: M,
      easing: V
    } = xe({
      style: T,
      timeout: l,
      easing: i
    }, {
      mode: "enter"
    });
    let G;
    l === "auto" ? (G = m.transitions.getAutoHeightDuration(P.clientHeight), c.current = G) : G = O, P.style.transition = [m.transitions.create("opacity", {
      duration: G,
      delay: M
    }), m.transitions.create("transform", {
      duration: Se ? G : G * 0.666,
      delay: M,
      easing: V
    })].join(","), h && h(P, x);
  }), L = b(g), q = b(N), j = b((P) => {
    const {
      duration: x,
      delay: O,
      easing: M
    } = xe({
      style: T,
      timeout: l,
      easing: i
    }, {
      mode: "exit"
    });
    let V;
    l === "auto" ? (V = m.transitions.getAutoHeightDuration(P.clientHeight), c.current = V) : V = x, P.style.transition = [m.transitions.create("opacity", {
      duration: V,
      delay: O
    }), m.transitions.create("transform", {
      duration: Se ? V : V * 0.666,
      delay: Se ? O : O || V * 0.333,
      easing: M
    })].join(","), P.style.opacity = 0, P.style.transform = Fe(0.75), y && y(P);
  }), B = b(k);
  return /* @__PURE__ */ C(d, R({
    appear: a,
    in: f,
    nodeRef: v,
    onEnter: A,
    onEntered: L,
    onEntering: D,
    onExit: j,
    onExited: B,
    onExiting: q,
    addEndListener: (P) => {
      l === "auto" && S.start(c.current || 0, P), r && r(v.current, P);
    },
    timeout: l === "auto" ? null : l
  }, p, {
    children: (P, x) => /* @__PURE__ */ u.cloneElement(s, R({
      style: R({
        opacity: 0,
        transform: Fe(0.75),
        visibility: P === "exited" && !f ? "hidden" : void 0
      }, Wn[P], T, s.props.style),
      ref: w
    }, x))
  }));
});
process.env.NODE_ENV !== "production" && (_e.propTypes = {
  // ┌────────────────────────────── Warning ──────────────────────────────┐
  // │ These PropTypes are generated from the TypeScript type definitions. │
  // │    To update them, edit the d.ts file and run `pnpm proptypes`.     │
  // └─────────────────────────────────────────────────────────────────────┘
  /**
   * Add a custom transition end trigger. Called with the transitioning DOM
   * node and a done callback. Allows for more fine grained transition end
   * logic. Note: Timeouts are still used as a fallback if provided.
   */
  addEndListener: e.func,
  /**
   * Perform the enter transition when it first mounts if `in` is also `true`.
   * Set this to `false` to disable this behavior.
   * @default true
   */
  appear: e.bool,
  /**
   * A single child content element.
   */
  children: Ce.isRequired,
  /**
   * The transition timing function.
   * You may specify a single easing or a object containing enter and exit values.
   */
  easing: e.oneOfType([e.shape({
    enter: e.string,
    exit: e.string
  }), e.string]),
  /**
   * If `true`, the component will transition in.
   */
  in: e.bool,
  /**
   * @ignore
   */
  onEnter: e.func,
  /**
   * @ignore
   */
  onEntered: e.func,
  /**
   * @ignore
   */
  onEntering: e.func,
  /**
   * @ignore
   */
  onExit: e.func,
  /**
   * @ignore
   */
  onExited: e.func,
  /**
   * @ignore
   */
  onExiting: e.func,
  /**
   * @ignore
   */
  style: e.object,
  /**
   * The duration for the transition, in milliseconds.
   * You may specify a single timeout for all transitions, or individually with an object.
   *
   * Set to 'auto' to automatically calculate transition time based on height.
   * @default 'auto'
   */
  timeout: e.oneOfType([e.oneOf(["auto"]), e.number, e.shape({
    appear: e.number,
    enter: e.number,
    exit: e.number
  })])
});
_e.muiSupportAuto = !0;
const Jn = _e;
function Gn(n) {
  const t = J(n);
  return t.body === n ? ve(n).innerWidth > t.documentElement.clientWidth : n.scrollHeight > n.clientHeight;
}
function ge(n, t) {
  t ? n.setAttribute("aria-hidden", "true") : n.removeAttribute("aria-hidden");
}
function Je(n) {
  return parseInt(ve(n).getComputedStyle(n).paddingRight, 10) || 0;
}
function Yn(n) {
  const o = ["TEMPLATE", "SCRIPT", "STYLE", "LINK", "MAP", "META", "NOSCRIPT", "PICTURE", "COL", "COLGROUP", "PARAM", "SLOT", "SOURCE", "TRACK"].indexOf(n.tagName) !== -1, r = n.tagName === "INPUT" && n.getAttribute("type") === "hidden";
  return o || r;
}
function Ge(n, t, o, r, a) {
  const s = [t, o, ...r];
  [].forEach.call(n.children, (i) => {
    const f = s.indexOf(i) === -1, h = !Yn(i);
    f && h && ge(i, a);
  });
}
function Ie(n, t) {
  let o = -1;
  return n.some((r, a) => t(r) ? (o = a, !0) : !1), o;
}
function Xn(n, t) {
  const o = [], r = n.container;
  if (!t.disableScrollLock) {
    if (Gn(r)) {
      const i = an(J(r));
      o.push({
        value: r.style.paddingRight,
        property: "padding-right",
        el: r
      }), r.style.paddingRight = `${Je(r) + i}px`;
      const f = J(r).querySelectorAll(".mui-fixed");
      [].forEach.call(f, (h) => {
        o.push({
          value: h.style.paddingRight,
          property: "padding-right",
          el: h
        }), h.style.paddingRight = `${Je(h) + i}px`;
      });
    }
    let s;
    if (r.parentNode instanceof DocumentFragment)
      s = J(r).body;
    else {
      const i = r.parentElement, f = ve(r);
      s = (i == null ? void 0 : i.nodeName) === "HTML" && f.getComputedStyle(i).overflowY === "scroll" ? i : r;
    }
    o.push({
      value: s.style.overflow,
      property: "overflow",
      el: s
    }, {
      value: s.style.overflowX,
      property: "overflow-x",
      el: s
    }, {
      value: s.style.overflowY,
      property: "overflow-y",
      el: s
    }), s.style.overflow = "hidden";
  }
  return () => {
    o.forEach(({
      value: s,
      el: i,
      property: f
    }) => {
      s ? i.style.setProperty(f, s) : i.style.removeProperty(f);
    });
  };
}
function Qn(n) {
  const t = [];
  return [].forEach.call(n.children, (o) => {
    o.getAttribute("aria-hidden") === "true" && t.push(o);
  }), t;
}
class Zn {
  constructor() {
    this.containers = void 0, this.modals = void 0, this.modals = [], this.containers = [];
  }
  add(t, o) {
    let r = this.modals.indexOf(t);
    if (r !== -1)
      return r;
    r = this.modals.length, this.modals.push(t), t.modalRef && ge(t.modalRef, !1);
    const a = Qn(o);
    Ge(o, t.mount, t.modalRef, a, !0);
    const s = Ie(this.containers, (i) => i.container === o);
    return s !== -1 ? (this.containers[s].modals.push(t), r) : (this.containers.push({
      modals: [t],
      container: o,
      restore: null,
      hiddenSiblings: a
    }), r);
  }
  mount(t, o) {
    const r = Ie(this.containers, (s) => s.modals.indexOf(t) !== -1), a = this.containers[r];
    a.restore || (a.restore = Xn(a, o));
  }
  remove(t, o = !0) {
    const r = this.modals.indexOf(t);
    if (r === -1)
      return r;
    const a = Ie(this.containers, (i) => i.modals.indexOf(t) !== -1), s = this.containers[a];
    if (s.modals.splice(s.modals.indexOf(t), 1), this.modals.splice(r, 1), s.modals.length === 0)
      s.restore && s.restore(), t.modalRef && ge(t.modalRef, o), Ge(s.container, t.mount, t.modalRef, s.hiddenSiblings, !1), this.containers.splice(a, 1);
    else {
      const i = s.modals[s.modals.length - 1];
      i.modalRef && ge(i.modalRef, !1);
    }
    return r;
  }
  isTopModal(t) {
    return this.modals.length > 0 && this.modals[this.modals.length - 1] === t;
  }
}
function et(n) {
  return typeof n == "function" ? n() : n;
}
function nt(n) {
  return n ? n.props.hasOwnProperty("in") : !1;
}
const tt = new Zn();
function ot(n) {
  const {
    container: t,
    disableEscapeKeyDown: o = !1,
    disableScrollLock: r = !1,
    // @ts-ignore internal logic - Base UI supports the manager as a prop too
    manager: a = tt,
    closeAfterTransition: s = !1,
    onTransitionEnter: i,
    onTransitionExited: f,
    children: h,
    onClose: g,
    open: E,
    rootRef: y
  } = n, k = u.useRef({}), N = u.useRef(null), T = u.useRef(null), l = ne(T, y), [d, p] = u.useState(!E), S = nt(h);
  let c = !0;
  (n["aria-hidden"] === "false" || n["aria-hidden"] === !1) && (c = !1);
  const m = () => J(N.current), v = () => (k.current.modalRef = T.current, k.current.mount = N.current, k.current), w = () => {
    a.mount(v(), {
      disableScrollLock: r
    }), T.current && (T.current.scrollTop = 0);
  }, b = ze(() => {
    const x = et(t) || m().body;
    a.add(v(), x), T.current && w();
  }), D = u.useCallback(() => a.isTopModal(v()), [a]), A = ze((x) => {
    N.current = x, x && (E && D() ? w() : T.current && ge(T.current, c));
  }), L = u.useCallback(() => {
    a.remove(v(), c);
  }, [c, a]);
  u.useEffect(() => () => {
    L();
  }, [L]), u.useEffect(() => {
    E ? b() : (!S || !s) && L();
  }, [E, L, S, s, b]);
  const q = (x) => (O) => {
    var M;
    (M = x.onKeyDown) == null || M.call(x, O), !(O.key !== "Escape" || O.which === 229 || // Wait until IME is settled.
    !D()) && (o || (O.stopPropagation(), g && g(O, "escapeKeyDown")));
  }, j = (x) => (O) => {
    var M;
    (M = x.onClick) == null || M.call(x, O), O.target === O.currentTarget && g && g(O, "backdropClick");
  };
  return {
    getRootProps: (x = {}) => {
      const O = xn(n);
      delete O.onTransitionEnter, delete O.onTransitionExited;
      const M = R({}, O, x);
      return R({
        role: "presentation"
      }, M, {
        onKeyDown: q(M),
        ref: l
      });
    },
    getBackdropProps: (x = {}) => {
      const O = x;
      return R({
        "aria-hidden": !0
      }, O, {
        onClick: j(O),
        open: E
      });
    },
    getTransitionProps: () => {
      const x = () => {
        p(!1), i && i();
      }, O = () => {
        p(!0), f && f(), s && L();
      };
      return {
        onEnter: Ke(x, h == null ? void 0 : h.props.onEnter),
        onExited: Ke(O, h == null ? void 0 : h.props.onExited)
      };
    },
    rootRef: l,
    portalRef: A,
    isTopModal: D,
    exited: d,
    hasTransition: S
  };
}
const rt = ["input", "select", "textarea", "a[href]", "button", "[tabindex]", "audio[controls]", "video[controls]", '[contenteditable]:not([contenteditable="false"])'].join(",");
function st(n) {
  const t = parseInt(n.getAttribute("tabindex") || "", 10);
  return Number.isNaN(t) ? n.contentEditable === "true" || (n.nodeName === "AUDIO" || n.nodeName === "VIDEO" || n.nodeName === "DETAILS") && n.getAttribute("tabindex") === null ? 0 : n.tabIndex : t;
}
function it(n) {
  if (n.tagName !== "INPUT" || n.type !== "radio" || !n.name)
    return !1;
  const t = (r) => n.ownerDocument.querySelector(`input[type="radio"]${r}`);
  let o = t(`[name="${n.name}"]:checked`);
  return o || (o = t(`[name="${n.name}"]`)), o !== n;
}
function at(n) {
  return !(n.disabled || n.tagName === "INPUT" && n.type === "hidden" || it(n));
}
function lt(n) {
  const t = [], o = [];
  return Array.from(n.querySelectorAll(rt)).forEach((r, a) => {
    const s = st(r);
    s === -1 || !at(r) || (s === 0 ? t.push(r) : o.push({
      documentOrder: a,
      tabIndex: s,
      node: r
    }));
  }), o.sort((r, a) => r.tabIndex === a.tabIndex ? r.documentOrder - a.documentOrder : r.tabIndex - a.tabIndex).map((r) => r.node).concat(t);
}
function ct() {
  return !0;
}
function Re(n) {
  const {
    children: t,
    disableAutoFocus: o = !1,
    disableEnforceFocus: r = !1,
    disableRestoreFocus: a = !1,
    getTabbable: s = lt,
    isEnabled: i = ct,
    open: f
  } = n, h = u.useRef(!1), g = u.useRef(null), E = u.useRef(null), y = u.useRef(null), k = u.useRef(null), N = u.useRef(!1), T = u.useRef(null), l = ne(t.ref, T), d = u.useRef(null);
  u.useEffect(() => {
    !f || !T.current || (N.current = !o);
  }, [o, f]), u.useEffect(() => {
    if (!f || !T.current)
      return;
    const c = J(T.current);
    return T.current.contains(c.activeElement) || (T.current.hasAttribute("tabIndex") || (process.env.NODE_ENV !== "production" && console.error(["MUI: The modal content node does not accept focus.", 'For the benefit of assistive technologies, the tabIndex of the node is being set to "-1".'].join(`
`)), T.current.setAttribute("tabIndex", "-1")), N.current && T.current.focus()), () => {
      a || (y.current && y.current.focus && (h.current = !0, y.current.focus()), y.current = null);
    };
  }, [f]), u.useEffect(() => {
    if (!f || !T.current)
      return;
    const c = J(T.current), m = (b) => {
      d.current = b, !(r || !i() || b.key !== "Tab") && c.activeElement === T.current && b.shiftKey && (h.current = !0, E.current && E.current.focus());
    }, v = () => {
      const b = T.current;
      if (b === null)
        return;
      if (!c.hasFocus() || !i() || h.current) {
        h.current = !1;
        return;
      }
      if (b.contains(c.activeElement) || r && c.activeElement !== g.current && c.activeElement !== E.current)
        return;
      if (c.activeElement !== k.current)
        k.current = null;
      else if (k.current !== null)
        return;
      if (!N.current)
        return;
      let D = [];
      if ((c.activeElement === g.current || c.activeElement === E.current) && (D = s(T.current)), D.length > 0) {
        var A, L;
        const q = !!((A = d.current) != null && A.shiftKey && ((L = d.current) == null ? void 0 : L.key) === "Tab"), j = D[0], B = D[D.length - 1];
        typeof j != "string" && typeof B != "string" && (q ? B.focus() : j.focus());
      } else
        b.focus();
    };
    c.addEventListener("focusin", v), c.addEventListener("keydown", m, !0);
    const w = setInterval(() => {
      c.activeElement && c.activeElement.tagName === "BODY" && v();
    }, 50);
    return () => {
      clearInterval(w), c.removeEventListener("focusin", v), c.removeEventListener("keydown", m, !0);
    };
  }, [o, r, a, i, f, s]);
  const p = (c) => {
    y.current === null && (y.current = c.relatedTarget), N.current = !0, k.current = c.target;
    const m = t.props.onFocus;
    m && m(c);
  }, S = (c) => {
    y.current === null && (y.current = c.relatedTarget), N.current = !0;
  };
  return /* @__PURE__ */ z(u.Fragment, {
    children: [/* @__PURE__ */ C("div", {
      tabIndex: f ? 0 : -1,
      onFocus: S,
      ref: g,
      "data-testid": "sentinelStart"
    }), /* @__PURE__ */ u.cloneElement(t, {
      ref: l,
      onFocus: p
    }), /* @__PURE__ */ C("div", {
      tabIndex: f ? 0 : -1,
      onFocus: S,
      ref: E,
      "data-testid": "sentinelEnd"
    })]
  });
}
process.env.NODE_ENV !== "production" && (Re.propTypes = {
  // ┌────────────────────────────── Warning ──────────────────────────────┐
  // │ These PropTypes are generated from the TypeScript type definitions. │
  // │ To update them, edit the TypeScript types and run `pnpm proptypes`. │
  // └─────────────────────────────────────────────────────────────────────┘
  /**
   * A single child content element.
   */
  children: Ce,
  /**
   * If `true`, the focus trap will not automatically shift focus to itself when it opens, and
   * replace it to the last focused element when it closes.
   * This also works correctly with any focus trap children that have the `disableAutoFocus` prop.
   *
   * Generally this should never be set to `true` as it makes the focus trap less
   * accessible to assistive technologies, like screen readers.
   * @default false
   */
  disableAutoFocus: e.bool,
  /**
   * If `true`, the focus trap will not prevent focus from leaving the focus trap while open.
   *
   * Generally this should never be set to `true` as it makes the focus trap less
   * accessible to assistive technologies, like screen readers.
   * @default false
   */
  disableEnforceFocus: e.bool,
  /**
   * If `true`, the focus trap will not restore focus to previously focused element once
   * focus trap is hidden or unmounted.
   * @default false
   */
  disableRestoreFocus: e.bool,
  /**
   * Returns an array of ordered tabbable nodes (i.e. in tab order) within the root.
   * For instance, you can provide the "tabbable" npm dependency.
   * @param {HTMLElement} root
   */
  getTabbable: e.func,
  /**
   * This prop extends the `open` prop.
   * It allows to toggle the open state without having to wait for a rerender when changing the `open` prop.
   * This prop should be memoized.
   * It can be used to support multiple focus trap mounted at the same time.
   * @default function defaultIsEnabled(): boolean {
   *   return true;
   * }
   */
  isEnabled: e.func,
  /**
   * If `true`, focus is locked.
   */
  open: e.bool.isRequired
});
process.env.NODE_ENV !== "production" && (Re["propTypes"] = en(Re.propTypes));
function ut(n) {
  return typeof n == "function" ? n() : n;
}
const Ne = /* @__PURE__ */ u.forwardRef(function(t, o) {
  const {
    children: r,
    container: a,
    disablePortal: s = !1
  } = t, [i, f] = u.useState(null), h = ne(/* @__PURE__ */ u.isValidElement(r) ? r.ref : null, o);
  if ($e(() => {
    s || f(ut(a) || document.body);
  }, [a, s]), $e(() => {
    if (i && !s)
      return Ue(o, i), () => {
        Ue(o, null);
      };
  }, [o, i, s]), s) {
    if (/* @__PURE__ */ u.isValidElement(r)) {
      const g = {
        ref: h
      };
      return /* @__PURE__ */ u.cloneElement(r, g);
    }
    return /* @__PURE__ */ C(u.Fragment, {
      children: r
    });
  }
  return /* @__PURE__ */ C(u.Fragment, {
    children: i && /* @__PURE__ */ kn.createPortal(r, i)
  });
});
process.env.NODE_ENV !== "production" && (Ne.propTypes = {
  // ┌────────────────────────────── Warning ──────────────────────────────┐
  // │ These PropTypes are generated from the TypeScript type definitions. │
  // │ To update them, edit the TypeScript types and run `pnpm proptypes`. │
  // └─────────────────────────────────────────────────────────────────────┘
  /**
   * The children to render into the `container`.
   */
  children: e.node,
  /**
   * An HTML element or function that returns one.
   * The `container` will have the portal children appended to it.
   *
   * You can also provide a callback, which is called in a React layout effect.
   * This lets you set the container from a ref, and also makes server-side rendering possible.
   *
   * By default, it uses the body of the top-level document object,
   * so it's simply `document.body` most of the time.
   */
  container: e.oneOfType([ye, e.func]),
  /**
   * The `children` will be under the DOM hierarchy of the parent component.
   * @default false
   */
  disablePortal: e.bool
});
process.env.NODE_ENV !== "production" && (Ne["propTypes"] = en(Ne.propTypes));
const dt = ["addEndListener", "appear", "children", "easing", "in", "onEnter", "onEntered", "onEntering", "onExit", "onExited", "onExiting", "style", "timeout", "TransitionComponent"], pt = {
  entering: {
    opacity: 1
  },
  entered: {
    opacity: 1
  }
}, mn = /* @__PURE__ */ u.forwardRef(function(t, o) {
  const r = Le(), a = {
    enter: r.transitions.duration.enteringScreen,
    exit: r.transitions.duration.leavingScreen
  }, {
    addEndListener: s,
    appear: i = !0,
    children: f,
    easing: h,
    in: g,
    onEnter: E,
    onEntered: y,
    onEntering: k,
    onExit: N,
    onExited: T,
    onExiting: l,
    style: d,
    timeout: p = a,
    // eslint-disable-next-line react/prop-types
    TransitionComponent: S = tn
  } = t, c = K(t, dt), m = u.useRef(null), v = ne(m, f.ref, o), w = (I) => (P) => {
    if (I) {
      const x = m.current;
      P === void 0 ? I(x) : I(x, P);
    }
  }, b = w(k), D = w((I, P) => {
    on(I);
    const x = xe({
      style: d,
      timeout: p,
      easing: h
    }, {
      mode: "enter"
    });
    I.style.webkitTransition = r.transitions.create("opacity", x), I.style.transition = r.transitions.create("opacity", x), E && E(I, P);
  }), A = w(y), L = w(l), q = w((I) => {
    const P = xe({
      style: d,
      timeout: p,
      easing: h
    }, {
      mode: "exit"
    });
    I.style.webkitTransition = r.transitions.create("opacity", P), I.style.transition = r.transitions.create("opacity", P), N && N(I);
  }), j = w(T);
  return /* @__PURE__ */ C(S, R({
    appear: i,
    in: g,
    nodeRef: m,
    onEnter: D,
    onEntered: A,
    onEntering: b,
    onExit: q,
    onExited: j,
    onExiting: L,
    addEndListener: (I) => {
      s && s(m.current, I);
    },
    timeout: p
  }, c, {
    children: (I, P) => /* @__PURE__ */ u.cloneElement(f, R({
      style: R({
        opacity: 0,
        visibility: I === "exited" && !g ? "hidden" : void 0
      }, pt[I], d, f.props.style),
      ref: v
    }, P))
  }));
});
process.env.NODE_ENV !== "production" && (mn.propTypes = {
  // ┌────────────────────────────── Warning ──────────────────────────────┐
  // │ These PropTypes are generated from the TypeScript type definitions. │
  // │    To update them, edit the d.ts file and run `pnpm proptypes`.     │
  // └─────────────────────────────────────────────────────────────────────┘
  /**
   * Add a custom transition end trigger. Called with the transitioning DOM
   * node and a done callback. Allows for more fine grained transition end
   * logic. Note: Timeouts are still used as a fallback if provided.
   */
  addEndListener: e.func,
  /**
   * Perform the enter transition when it first mounts if `in` is also `true`.
   * Set this to `false` to disable this behavior.
   * @default true
   */
  appear: e.bool,
  /**
   * A single child content element.
   */
  children: Ce.isRequired,
  /**
   * The transition timing function.
   * You may specify a single easing or a object containing enter and exit values.
   */
  easing: e.oneOfType([e.shape({
    enter: e.string,
    exit: e.string
  }), e.string]),
  /**
   * If `true`, the component will transition in.
   */
  in: e.bool,
  /**
   * @ignore
   */
  onEnter: e.func,
  /**
   * @ignore
   */
  onEntered: e.func,
  /**
   * @ignore
   */
  onEntering: e.func,
  /**
   * @ignore
   */
  onExit: e.func,
  /**
   * @ignore
   */
  onExited: e.func,
  /**
   * @ignore
   */
  onExiting: e.func,
  /**
   * @ignore
   */
  style: e.object,
  /**
   * The duration for the transition, in milliseconds.
   * You may specify a single timeout for all transitions, or individually with an object.
   * @default {
   *   enter: theme.transitions.duration.enteringScreen,
   *   exit: theme.transitions.duration.leavingScreen,
   * }
   */
  timeout: e.oneOfType([e.number, e.shape({
    appear: e.number,
    enter: e.number,
    exit: e.number
  })])
});
const ft = mn;
function ht(n) {
  return le("MuiBackdrop", n);
}
ce("MuiBackdrop", ["root", "invisible"]);
const mt = ["children", "className", "component", "components", "componentsProps", "invisible", "open", "slotProps", "slots", "TransitionComponent", "transitionDuration"], bt = (n) => {
  const {
    classes: t,
    invisible: o
  } = n;
  return de({
    root: ["root", o && "invisible"]
  }, ht, t);
}, gt = Y("div", {
  name: "MuiBackdrop",
  slot: "Root",
  overridesResolver: (n, t) => {
    const {
      ownerState: o
    } = n;
    return [t.root, o.invisible && t.invisible];
  }
})(({
  ownerState: n
}) => R({
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  WebkitTapHighlightColor: "transparent"
}, n.invisible && {
  backgroundColor: "transparent"
})), bn = /* @__PURE__ */ u.forwardRef(function(t, o) {
  var r, a, s;
  const i = ue({
    props: t,
    name: "MuiBackdrop"
  }), {
    children: f,
    className: h,
    component: g = "div",
    components: E = {},
    componentsProps: y = {},
    invisible: k = !1,
    open: N,
    slotProps: T = {},
    slots: l = {},
    TransitionComponent: d = ft,
    transitionDuration: p
  } = i, S = K(i, mt), c = R({}, i, {
    component: g,
    invisible: k
  }), m = bt(c), v = (r = T.root) != null ? r : y.root;
  return /* @__PURE__ */ C(d, R({
    in: N,
    timeout: p
  }, S, {
    children: /* @__PURE__ */ C(gt, R({
      "aria-hidden": !0
    }, v, {
      as: (a = (s = l.root) != null ? s : E.Root) != null ? a : g,
      className: Q(m.root, h, v == null ? void 0 : v.className),
      ownerState: R({}, c, v == null ? void 0 : v.ownerState),
      classes: m,
      ref: o,
      children: f
    }))
  }));
});
process.env.NODE_ENV !== "production" && (bn.propTypes = {
  // ┌────────────────────────────── Warning ──────────────────────────────┐
  // │ These PropTypes are generated from the TypeScript type definitions. │
  // │    To update them, edit the d.ts file and run `pnpm proptypes`.     │
  // └─────────────────────────────────────────────────────────────────────┘
  /**
   * The content of the component.
   */
  children: e.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: e.object,
  /**
   * @ignore
   */
  className: e.string,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: e.elementType,
  /**
   * The components used for each slot inside.
   *
   * This prop is an alias for the `slots` prop.
   * It's recommended to use the `slots` prop instead.
   *
   * @default {}
   */
  components: e.shape({
    Root: e.elementType
  }),
  /**
   * The extra props for the slot components.
   * You can override the existing props or add new ones.
   *
   * This prop is an alias for the `slotProps` prop.
   * It's recommended to use the `slotProps` prop instead, as `componentsProps` will be deprecated in the future.
   *
   * @default {}
   */
  componentsProps: e.shape({
    root: e.object
  }),
  /**
   * If `true`, the backdrop is invisible.
   * It can be used when rendering a popover or a custom select component.
   * @default false
   */
  invisible: e.bool,
  /**
   * If `true`, the component is shown.
   */
  open: e.bool.isRequired,
  /**
   * The extra props for the slot components.
   * You can override the existing props or add new ones.
   *
   * This prop is an alias for the `componentsProps` prop, which will be deprecated in the future.
   *
   * @default {}
   */
  slotProps: e.shape({
    root: e.object
  }),
  /**
   * The components used for each slot inside.
   *
   * This prop is an alias for the `components` prop, which will be deprecated in the future.
   *
   * @default {}
   */
  slots: e.shape({
    root: e.elementType
  }),
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: e.oneOfType([e.arrayOf(e.oneOfType([e.func, e.object, e.bool])), e.func, e.object]),
  /**
   * The component used for the transition.
   * [Follow this guide](/material-ui/transitions/#transitioncomponent-prop) to learn more about the requirements for this component.
   * @default Fade
   */
  TransitionComponent: e.elementType,
  /**
   * The duration for the transition, in milliseconds.
   * You may specify a single timeout for all transitions, or individually with an object.
   */
  transitionDuration: e.oneOfType([e.number, e.shape({
    appear: e.number,
    enter: e.number,
    exit: e.number
  })])
});
const vt = bn;
function yt(n) {
  return le("MuiModal", n);
}
ce("MuiModal", ["root", "hidden", "backdrop"]);
const Et = ["BackdropComponent", "BackdropProps", "classes", "className", "closeAfterTransition", "children", "container", "component", "components", "componentsProps", "disableAutoFocus", "disableEnforceFocus", "disableEscapeKeyDown", "disablePortal", "disableRestoreFocus", "disableScrollLock", "hideBackdrop", "keepMounted", "onBackdropClick", "onClose", "onTransitionEnter", "onTransitionExited", "open", "slotProps", "slots", "theme"], Tt = (n) => {
  const {
    open: t,
    exited: o,
    classes: r
  } = n;
  return de({
    root: ["root", !t && o && "hidden"],
    backdrop: ["backdrop"]
  }, yt, r);
}, Pt = Y("div", {
  name: "MuiModal",
  slot: "Root",
  overridesResolver: (n, t) => {
    const {
      ownerState: o
    } = n;
    return [t.root, !o.open && o.exited && t.hidden];
  }
})(({
  theme: n,
  ownerState: t
}) => R({
  position: "fixed",
  zIndex: (n.vars || n).zIndex.modal,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0
}, !t.open && t.exited && {
  visibility: "hidden"
})), xt = Y(vt, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (n, t) => t.backdrop
})({
  zIndex: -1
}), gn = /* @__PURE__ */ u.forwardRef(function(t, o) {
  var r, a, s, i, f, h;
  const g = ue({
    name: "MuiModal",
    props: t
  }), {
    BackdropComponent: E = xt,
    BackdropProps: y,
    className: k,
    closeAfterTransition: N = !1,
    children: T,
    container: l,
    component: d,
    components: p = {},
    componentsProps: S = {},
    disableAutoFocus: c = !1,
    disableEnforceFocus: m = !1,
    disableEscapeKeyDown: v = !1,
    disablePortal: w = !1,
    disableRestoreFocus: b = !1,
    disableScrollLock: D = !1,
    hideBackdrop: A = !1,
    keepMounted: L = !1,
    onBackdropClick: q,
    open: j,
    slotProps: B,
    slots: I
    // eslint-disable-next-line react/prop-types
  } = g, P = K(g, Et), x = R({}, g, {
    closeAfterTransition: N,
    disableAutoFocus: c,
    disableEnforceFocus: m,
    disableEscapeKeyDown: v,
    disablePortal: w,
    disableRestoreFocus: b,
    disableScrollLock: D,
    hideBackdrop: A,
    keepMounted: L
  }), {
    getRootProps: O,
    getBackdropProps: M,
    getTransitionProps: V,
    portalRef: G,
    isTopModal: Ee,
    exited: U,
    hasTransition: Te
  } = ot(R({}, x, {
    rootRef: o
  })), Z = R({}, x, {
    exited: U
  }), X = Tt(Z), te = {};
  if (T.props.tabIndex === void 0 && (te.tabIndex = "-1"), Te) {
    const {
      onEnter: $,
      onExited: F
    } = V();
    te.onEnter = $, te.onExited = F;
  }
  const oe = (r = (a = I == null ? void 0 : I.root) != null ? a : p.Root) != null ? r : Pt, pe = (s = (i = I == null ? void 0 : I.backdrop) != null ? i : p.Backdrop) != null ? s : E, fe = (f = B == null ? void 0 : B.root) != null ? f : S.root, re = (h = B == null ? void 0 : B.backdrop) != null ? h : S.backdrop, we = ae({
    elementType: oe,
    externalSlotProps: fe,
    externalForwardedProps: P,
    getSlotProps: O,
    additionalProps: {
      ref: o,
      as: d
    },
    ownerState: Z,
    className: Q(k, fe == null ? void 0 : fe.className, X == null ? void 0 : X.root, !Z.open && Z.exited && (X == null ? void 0 : X.hidden))
  }), ke = ae({
    elementType: pe,
    externalSlotProps: re,
    additionalProps: y,
    getSlotProps: ($) => M(R({}, $, {
      onClick: (F) => {
        q && q(F), $ != null && $.onClick && $.onClick(F);
      }
    })),
    className: Q(re == null ? void 0 : re.className, y == null ? void 0 : y.className, X == null ? void 0 : X.backdrop),
    ownerState: Z
  });
  return !L && !j && (!Te || U) ? null : /* @__PURE__ */ C(Ne, {
    ref: G,
    container: l,
    disablePortal: w,
    children: /* @__PURE__ */ z(oe, R({}, we, {
      children: [!A && E ? /* @__PURE__ */ C(pe, R({}, ke)) : null, /* @__PURE__ */ C(Re, {
        disableEnforceFocus: m,
        disableAutoFocus: c,
        disableRestoreFocus: b,
        isEnabled: Ee,
        open: j,
        children: /* @__PURE__ */ u.cloneElement(T, te)
      })]
    }))
  });
});
process.env.NODE_ENV !== "production" && (gn.propTypes = {
  // ┌────────────────────────────── Warning ──────────────────────────────┐
  // │ These PropTypes are generated from the TypeScript type definitions. │
  // │    To update them, edit the d.ts file and run `pnpm proptypes`.     │
  // └─────────────────────────────────────────────────────────────────────┘
  /**
   * A backdrop component. This prop enables custom backdrop rendering.
   * @deprecated Use `slots.backdrop` instead. While this prop currently works, it will be removed in the next major version.
   * Use the `slots.backdrop` prop to make your application ready for the next version of Material UI.
   * @default styled(Backdrop, {
   *   name: 'MuiModal',
   *   slot: 'Backdrop',
   *   overridesResolver: (props, styles) => {
   *     return styles.backdrop;
   *   },
   * })({
   *   zIndex: -1,
   * })
   */
  BackdropComponent: e.elementType,
  /**
   * Props applied to the [`Backdrop`](/material-ui/api/backdrop/) element.
   * @deprecated Use `slotProps.backdrop` instead.
   */
  BackdropProps: e.object,
  /**
   * A single child content element.
   */
  children: Ce.isRequired,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: e.object,
  /**
   * @ignore
   */
  className: e.string,
  /**
   * When set to true the Modal waits until a nested Transition is completed before closing.
   * @default false
   */
  closeAfterTransition: e.bool,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: e.elementType,
  /**
   * The components used for each slot inside.
   *
   * This prop is an alias for the `slots` prop.
   * It's recommended to use the `slots` prop instead.
   *
   * @default {}
   */
  components: e.shape({
    Backdrop: e.elementType,
    Root: e.elementType
  }),
  /**
   * The extra props for the slot components.
   * You can override the existing props or add new ones.
   *
   * This prop is an alias for the `slotProps` prop.
   * It's recommended to use the `slotProps` prop instead, as `componentsProps` will be deprecated in the future.
   *
   * @default {}
   */
  componentsProps: e.shape({
    backdrop: e.oneOfType([e.func, e.object]),
    root: e.oneOfType([e.func, e.object])
  }),
  /**
   * An HTML element or function that returns one.
   * The `container` will have the portal children appended to it.
   *
   * You can also provide a callback, which is called in a React layout effect.
   * This lets you set the container from a ref, and also makes server-side rendering possible.
   *
   * By default, it uses the body of the top-level document object,
   * so it's simply `document.body` most of the time.
   */
  container: e.oneOfType([ye, e.func]),
  /**
   * If `true`, the modal will not automatically shift focus to itself when it opens, and
   * replace it to the last focused element when it closes.
   * This also works correctly with any modal children that have the `disableAutoFocus` prop.
   *
   * Generally this should never be set to `true` as it makes the modal less
   * accessible to assistive technologies, like screen readers.
   * @default false
   */
  disableAutoFocus: e.bool,
  /**
   * If `true`, the modal will not prevent focus from leaving the modal while open.
   *
   * Generally this should never be set to `true` as it makes the modal less
   * accessible to assistive technologies, like screen readers.
   * @default false
   */
  disableEnforceFocus: e.bool,
  /**
   * If `true`, hitting escape will not fire the `onClose` callback.
   * @default false
   */
  disableEscapeKeyDown: e.bool,
  /**
   * The `children` will be under the DOM hierarchy of the parent component.
   * @default false
   */
  disablePortal: e.bool,
  /**
   * If `true`, the modal will not restore focus to previously focused element once
   * modal is hidden or unmounted.
   * @default false
   */
  disableRestoreFocus: e.bool,
  /**
   * Disable the scroll lock behavior.
   * @default false
   */
  disableScrollLock: e.bool,
  /**
   * If `true`, the backdrop is not rendered.
   * @default false
   */
  hideBackdrop: e.bool,
  /**
   * Always keep the children in the DOM.
   * This prop can be useful in SEO situation or
   * when you want to maximize the responsiveness of the Modal.
   * @default false
   */
  keepMounted: e.bool,
  /**
   * Callback fired when the backdrop is clicked.
   * @deprecated Use the `onClose` prop with the `reason` argument to handle the `backdropClick` events.
   */
  onBackdropClick: e.func,
  /**
   * Callback fired when the component requests to be closed.
   * The `reason` parameter can optionally be used to control the response to `onClose`.
   *
   * @param {object} event The event source of the callback.
   * @param {string} reason Can be: `"escapeKeyDown"`, `"backdropClick"`.
   */
  onClose: e.func,
  /**
   * A function called when a transition enters.
   */
  onTransitionEnter: e.func,
  /**
   * A function called when a transition has exited.
   */
  onTransitionExited: e.func,
  /**
   * If `true`, the component is shown.
   */
  open: e.bool.isRequired,
  /**
   * The props used for each slot inside the Modal.
   * @default {}
   */
  slotProps: e.shape({
    backdrop: e.oneOfType([e.func, e.object]),
    root: e.oneOfType([e.func, e.object])
  }),
  /**
   * The components used for each slot inside the Modal.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  slots: e.shape({
    backdrop: e.elementType,
    root: e.elementType
  }),
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: e.oneOfType([e.arrayOf(e.oneOfType([e.func, e.object, e.bool])), e.func, e.object])
});
const Rt = gn, Nt = (n) => {
  let t;
  return n < 1 ? t = 5.11916 * n ** 2 : t = 4.5 * Math.log(n + 1) + 2, (t / 100).toFixed(2);
}, Ye = Nt;
function Ot(n) {
  return le("MuiPaper", n);
}
ce("MuiPaper", ["root", "rounded", "outlined", "elevation", "elevation0", "elevation1", "elevation2", "elevation3", "elevation4", "elevation5", "elevation6", "elevation7", "elevation8", "elevation9", "elevation10", "elevation11", "elevation12", "elevation13", "elevation14", "elevation15", "elevation16", "elevation17", "elevation18", "elevation19", "elevation20", "elevation21", "elevation22", "elevation23", "elevation24"]);
const Ct = ["className", "component", "elevation", "square", "variant"], wt = (n) => {
  const {
    square: t,
    elevation: o,
    variant: r,
    classes: a
  } = n, s = {
    root: ["root", r, !t && "rounded", r === "elevation" && `elevation${o}`]
  };
  return de(s, Ot, a);
}, kt = Y("div", {
  name: "MuiPaper",
  slot: "Root",
  overridesResolver: (n, t) => {
    const {
      ownerState: o
    } = n;
    return [t.root, t[o.variant], !o.square && t.rounded, o.variant === "elevation" && t[`elevation${o.elevation}`]];
  }
})(({
  theme: n,
  ownerState: t
}) => {
  var o;
  return R({
    backgroundColor: (n.vars || n).palette.background.paper,
    color: (n.vars || n).palette.text.primary,
    transition: n.transitions.create("box-shadow")
  }, !t.square && {
    borderRadius: n.shape.borderRadius
  }, t.variant === "outlined" && {
    border: `1px solid ${(n.vars || n).palette.divider}`
  }, t.variant === "elevation" && R({
    boxShadow: (n.vars || n).shadows[t.elevation]
  }, !n.vars && n.palette.mode === "dark" && {
    backgroundImage: `linear-gradient(${He("#fff", Ye(t.elevation))}, ${He("#fff", Ye(t.elevation))})`
  }, n.vars && {
    backgroundImage: (o = n.vars.overlays) == null ? void 0 : o[t.elevation]
  }));
}), vn = /* @__PURE__ */ u.forwardRef(function(t, o) {
  const r = ue({
    props: t,
    name: "MuiPaper"
  }), {
    className: a,
    component: s = "div",
    elevation: i = 1,
    square: f = !1,
    variant: h = "elevation"
  } = r, g = K(r, Ct), E = R({}, r, {
    component: s,
    elevation: i,
    square: f,
    variant: h
  }), y = wt(E);
  return process.env.NODE_ENV !== "production" && Le().shadows[i] === void 0 && console.error([`MUI: The elevation provided <Paper elevation={${i}}> is not available in the theme.`, `Please make sure that \`theme.shadows[${i}]\` is defined.`].join(`
`)), /* @__PURE__ */ C(kt, R({
    as: s,
    ownerState: E,
    className: Q(y.root, a),
    ref: o
  }, g));
});
process.env.NODE_ENV !== "production" && (vn.propTypes = {
  // ┌────────────────────────────── Warning ──────────────────────────────┐
  // │ These PropTypes are generated from the TypeScript type definitions. │
  // │    To update them, edit the d.ts file and run `pnpm proptypes`.     │
  // └─────────────────────────────────────────────────────────────────────┘
  /**
   * The content of the component.
   */
  children: e.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: e.object,
  /**
   * @ignore
   */
  className: e.string,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: e.elementType,
  /**
   * Shadow depth, corresponds to `dp` in the spec.
   * It accepts values between 0 and 24 inclusive.
   * @default 1
   */
  elevation: Oe(un, (n) => {
    const {
      elevation: t,
      variant: o
    } = n;
    return t > 0 && o === "outlined" ? new Error(`MUI: Combining \`elevation={${t}}\` with \`variant="${o}"\` has no effect. Either use \`elevation={0}\` or use a different \`variant\`.`) : null;
  }),
  /**
   * If `true`, rounded corners are disabled.
   * @default false
   */
  square: e.bool,
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: e.oneOfType([e.arrayOf(e.oneOfType([e.func, e.object, e.bool])), e.func, e.object]),
  /**
   * The variant to use.
   * @default 'elevation'
   */
  variant: e.oneOfType([e.oneOf(["elevation", "outlined"]), e.string])
});
const Mt = vn;
function St(n) {
  return le("MuiPopover", n);
}
ce("MuiPopover", ["root", "paper"]);
const It = ["onEntering"], $t = ["action", "anchorEl", "anchorOrigin", "anchorPosition", "anchorReference", "children", "className", "container", "elevation", "marginThreshold", "open", "PaperProps", "slots", "slotProps", "transformOrigin", "TransitionComponent", "transitionDuration", "TransitionProps", "disableScrollLock"], Dt = ["slotProps"];
function Xe(n, t) {
  let o = 0;
  return typeof t == "number" ? o = t : t === "center" ? o = n.height / 2 : t === "bottom" && (o = n.height), o;
}
function Qe(n, t) {
  let o = 0;
  return typeof t == "number" ? o = t : t === "center" ? o = n.width / 2 : t === "right" && (o = n.width), o;
}
function Ze(n) {
  return [n.horizontal, n.vertical].map((t) => typeof t == "number" ? `${t}px` : t).join(" ");
}
function Pe(n) {
  return typeof n == "function" ? n() : n;
}
const Ft = (n) => {
  const {
    classes: t
  } = n;
  return de({
    root: ["root"],
    paper: ["paper"]
  }, St, t);
}, Lt = Y(Rt, {
  name: "MuiPopover",
  slot: "Root",
  overridesResolver: (n, t) => t.root
})({}), yn = Y(Mt, {
  name: "MuiPopover",
  slot: "Paper",
  overridesResolver: (n, t) => t.paper
})({
  position: "absolute",
  overflowY: "auto",
  overflowX: "hidden",
  // So we see the popover when it's empty.
  // It's most likely on issue on userland.
  minWidth: 16,
  minHeight: 16,
  maxWidth: "calc(100% - 32px)",
  maxHeight: "calc(100% - 32px)",
  // We disable the focus ring for mouse, touch and keyboard users.
  outline: 0
}), En = /* @__PURE__ */ u.forwardRef(function(t, o) {
  var r, a, s;
  const i = ue({
    props: t,
    name: "MuiPopover"
  }), {
    action: f,
    anchorEl: h,
    anchorOrigin: g = {
      vertical: "top",
      horizontal: "left"
    },
    anchorPosition: E,
    anchorReference: y = "anchorEl",
    children: k,
    className: N,
    container: T,
    elevation: l = 8,
    marginThreshold: d = 16,
    open: p,
    PaperProps: S = {},
    slots: c,
    slotProps: m,
    transformOrigin: v = {
      vertical: "top",
      horizontal: "left"
    },
    TransitionComponent: w = Jn,
    transitionDuration: b = "auto",
    TransitionProps: {
      onEntering: D
    } = {},
    disableScrollLock: A = !1
  } = i, L = K(i.TransitionProps, It), q = K(i, $t), j = (r = m == null ? void 0 : m.paper) != null ? r : S, B = u.useRef(), I = ne(B, j.ref), P = R({}, i, {
    anchorOrigin: g,
    anchorReference: y,
    elevation: l,
    marginThreshold: d,
    externalPaperSlotProps: j,
    transformOrigin: v,
    TransitionComponent: w,
    transitionDuration: b,
    TransitionProps: L
  }), x = Ft(P), O = u.useCallback(() => {
    if (y === "anchorPosition")
      return process.env.NODE_ENV !== "production" && (E || console.error('MUI: You need to provide a `anchorPosition` prop when using <Popover anchorReference="anchorPosition" />.')), E;
    const $ = Pe(h), F = $ && $.nodeType === 1 ? $ : J(B.current).body, H = F.getBoundingClientRect();
    if (process.env.NODE_ENV !== "production") {
      const ee = F.getBoundingClientRect();
      process.env.NODE_ENV !== "test" && ee.top === 0 && ee.left === 0 && ee.right === 0 && ee.bottom === 0 && console.warn(["MUI: The `anchorEl` prop provided to the component is invalid.", "The anchor element should be part of the document layout.", "Make sure the element is present in the document or that it's not display none."].join(`
`));
    }
    return {
      top: H.top + Xe(H, g.vertical),
      left: H.left + Qe(H, g.horizontal)
    };
  }, [h, g.horizontal, g.vertical, E, y]), M = u.useCallback(($) => ({
    vertical: Xe($, v.vertical),
    horizontal: Qe($, v.horizontal)
  }), [v.horizontal, v.vertical]), V = u.useCallback(($) => {
    const F = {
      width: $.offsetWidth,
      height: $.offsetHeight
    }, H = M(F);
    if (y === "none")
      return {
        top: null,
        left: null,
        transformOrigin: Ze(H)
      };
    const ee = O();
    let se = ee.top - H.vertical, ie = ee.left - H.horizontal;
    const je = se + F.height, Ae = ie + F.width, Be = ve(Pe(h)), he = Be.innerHeight - d, Ve = Be.innerWidth - d;
    if (d !== null && se < d) {
      const W = se - d;
      se -= W, H.vertical += W;
    } else if (d !== null && je > he) {
      const W = je - he;
      se -= W, H.vertical += W;
    }
    if (process.env.NODE_ENV !== "production" && F.height > he && F.height && he && console.error(["MUI: The popover component is too tall.", `Some part of it can not be seen on the screen (${F.height - he}px).`, "Please consider adding a `max-height` to improve the user-experience."].join(`
`)), d !== null && ie < d) {
      const W = ie - d;
      ie -= W, H.horizontal += W;
    } else if (Ae > Ve) {
      const W = Ae - Ve;
      ie -= W, H.horizontal += W;
    }
    return {
      top: `${Math.round(se)}px`,
      left: `${Math.round(ie)}px`,
      transformOrigin: Ze(H)
    };
  }, [h, y, O, M, d]), [G, Ee] = u.useState(p), U = u.useCallback(() => {
    const $ = B.current;
    if (!$)
      return;
    const F = V($);
    F.top !== null && ($.style.top = F.top), F.left !== null && ($.style.left = F.left), $.style.transformOrigin = F.transformOrigin, Ee(!0);
  }, [V]);
  u.useEffect(() => (A && window.addEventListener("scroll", U), () => window.removeEventListener("scroll", U)), [h, A, U]);
  const Te = ($, F) => {
    D && D($, F), U();
  }, Z = () => {
    Ee(!1);
  };
  u.useEffect(() => {
    p && U();
  }), u.useImperativeHandle(f, () => p ? {
    updatePosition: () => {
      U();
    }
  } : null, [p, U]), u.useEffect(() => {
    if (!p)
      return;
    const $ = wn(() => {
      U();
    }), F = ve(h);
    return F.addEventListener("resize", $), () => {
      $.clear(), F.removeEventListener("resize", $);
    };
  }, [h, p, U]);
  let X = b;
  b === "auto" && !w.muiSupportAuto && (X = void 0);
  const te = T || (h ? J(Pe(h)).body : void 0), oe = (a = c == null ? void 0 : c.root) != null ? a : Lt, pe = (s = c == null ? void 0 : c.paper) != null ? s : yn, fe = ae({
    elementType: pe,
    externalSlotProps: R({}, j, {
      style: G ? j.style : R({}, j.style, {
        opacity: 0
      })
    }),
    additionalProps: {
      elevation: l,
      ref: I
    },
    ownerState: P,
    className: Q(x.paper, j == null ? void 0 : j.className)
  }), re = ae({
    elementType: oe,
    externalSlotProps: (m == null ? void 0 : m.root) || {},
    externalForwardedProps: q,
    additionalProps: {
      ref: o,
      slotProps: {
        backdrop: {
          invisible: !0
        }
      },
      container: te,
      open: p
    },
    ownerState: P,
    className: Q(x.root, N)
  }), {
    slotProps: we
  } = re, ke = K(re, Dt);
  return /* @__PURE__ */ C(oe, R({}, ke, !Rn(oe) && {
    slotProps: we,
    disableScrollLock: A
  }, {
    children: /* @__PURE__ */ C(w, R({
      appear: !0,
      in: p,
      onEntering: Te,
      onExited: Z,
      timeout: X
    }, L, {
      children: /* @__PURE__ */ C(pe, R({}, fe, {
        children: k
      }))
    }))
  }));
});
process.env.NODE_ENV !== "production" && (En.propTypes = {
  // ┌────────────────────────────── Warning ──────────────────────────────┐
  // │ These PropTypes are generated from the TypeScript type definitions. │
  // │    To update them, edit the d.ts file and run `pnpm proptypes`.     │
  // └─────────────────────────────────────────────────────────────────────┘
  /**
   * A ref for imperative actions.
   * It currently only supports updatePosition() action.
   */
  action: In,
  /**
   * An HTML element, [PopoverVirtualElement](/material-ui/react-popover/#virtual-element),
   * or a function that returns either.
   * It's used to set the position of the popover.
   */
  anchorEl: Oe(e.oneOfType([ye, e.func]), (n) => {
    if (n.open && (!n.anchorReference || n.anchorReference === "anchorEl")) {
      const t = Pe(n.anchorEl);
      if (t && t.nodeType === 1) {
        const o = t.getBoundingClientRect();
        if (process.env.NODE_ENV !== "test" && o.top === 0 && o.left === 0 && o.right === 0 && o.bottom === 0)
          return new Error(["MUI: The `anchorEl` prop provided to the component is invalid.", "The anchor element should be part of the document layout.", "Make sure the element is present in the document or that it's not display none."].join(`
`));
      } else
        return new Error(["MUI: The `anchorEl` prop provided to the component is invalid.", `It should be an Element or PopoverVirtualElement instance but it's \`${t}\` instead.`].join(`
`));
    }
    return null;
  }),
  /**
   * This is the point on the anchor where the popover's
   * `anchorEl` will attach to. This is not used when the
   * anchorReference is 'anchorPosition'.
   *
   * Options:
   * vertical: [top, center, bottom];
   * horizontal: [left, center, right].
   * @default {
   *   vertical: 'top',
   *   horizontal: 'left',
   * }
   */
  anchorOrigin: e.shape({
    horizontal: e.oneOfType([e.oneOf(["center", "left", "right"]), e.number]).isRequired,
    vertical: e.oneOfType([e.oneOf(["bottom", "center", "top"]), e.number]).isRequired
  }),
  /**
   * This is the position that may be used to set the position of the popover.
   * The coordinates are relative to the application's client area.
   */
  anchorPosition: e.shape({
    left: e.number.isRequired,
    top: e.number.isRequired
  }),
  /**
   * This determines which anchor prop to refer to when setting
   * the position of the popover.
   * @default 'anchorEl'
   */
  anchorReference: e.oneOf(["anchorEl", "anchorPosition", "none"]),
  /**
   * The content of the component.
   */
  children: e.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: e.object,
  /**
   * @ignore
   */
  className: e.string,
  /**
   * An HTML element, component instance, or function that returns either.
   * The `container` will passed to the Modal component.
   *
   * By default, it uses the body of the anchorEl's top-level document object,
   * so it's simply `document.body` most of the time.
   */
  container: e.oneOfType([ye, e.func]),
  /**
   * Disable the scroll lock behavior.
   * @default false
   */
  disableScrollLock: e.bool,
  /**
   * The elevation of the popover.
   * @default 8
   */
  elevation: un,
  /**
   * Specifies how close to the edge of the window the popover can appear.
   * If null, the popover will not be constrained by the window.
   * @default 16
   */
  marginThreshold: e.number,
  /**
   * Callback fired when the component requests to be closed.
   * The `reason` parameter can optionally be used to control the response to `onClose`.
   */
  onClose: e.func,
  /**
   * If `true`, the component is shown.
   */
  open: e.bool.isRequired,
  /**
   * Props applied to the [`Paper`](/material-ui/api/paper/) element.
   *
   * This prop is an alias for `slotProps.paper` and will be overriden by it if both are used.
   * @deprecated Use `slotProps.paper` instead.
   *
   * @default {}
   */
  PaperProps: e.shape({
    component: On
  }),
  /**
   * The extra props for the slot components.
   * You can override the existing props or add new ones.
   *
   * @default {}
   */
  slotProps: e.shape({
    paper: e.oneOfType([e.func, e.object]),
    root: e.oneOfType([e.func, e.object])
  }),
  /**
   * The components used for each slot inside.
   *
   * @default {}
   */
  slots: e.shape({
    paper: e.elementType,
    root: e.elementType
  }),
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: e.oneOfType([e.arrayOf(e.oneOfType([e.func, e.object, e.bool])), e.func, e.object]),
  /**
   * This is the point on the popover which
   * will attach to the anchor's origin.
   *
   * Options:
   * vertical: [top, center, bottom, x(px)];
   * horizontal: [left, center, right, x(px)].
   * @default {
   *   vertical: 'top',
   *   horizontal: 'left',
   * }
   */
  transformOrigin: e.shape({
    horizontal: e.oneOfType([e.oneOf(["center", "left", "right"]), e.number]).isRequired,
    vertical: e.oneOfType([e.oneOf(["bottom", "center", "top"]), e.number]).isRequired
  }),
  /**
   * The component used for the transition.
   * [Follow this guide](/material-ui/transitions/#transitioncomponent-prop) to learn more about the requirements for this component.
   * @default Grow
   */
  TransitionComponent: e.elementType,
  /**
   * Set to 'auto' to automatically calculate transition time based on height.
   * @default 'auto'
   */
  transitionDuration: e.oneOfType([e.oneOf(["auto"]), e.number, e.shape({
    appear: e.number,
    enter: e.number,
    exit: e.number
  })]),
  /**
   * Props applied to the transition element.
   * By default, the element is based on this [`Transition`](https://reactcommunity.org/react-transition-group/transition/) component.
   * @default {}
   */
  TransitionProps: e.object
});
const _t = En;
function jt(n) {
  return le("MuiMenu", n);
}
ce("MuiMenu", ["root", "paper", "list"]);
const At = ["onEntering"], Bt = ["autoFocus", "children", "className", "disableAutoFocusItem", "MenuListProps", "onClose", "open", "PaperProps", "PopoverClasses", "transitionDuration", "TransitionProps", "variant", "slots", "slotProps"], Vt = {
  vertical: "top",
  horizontal: "right"
}, qt = {
  vertical: "top",
  horizontal: "left"
}, Ht = (n) => {
  const {
    classes: t
  } = n;
  return de({
    root: ["root"],
    paper: ["paper"],
    list: ["list"]
  }, jt, t);
}, Ut = Y(_t, {
  shouldForwardProp: (n) => Nn(n) || n === "classes",
  name: "MuiMenu",
  slot: "Root",
  overridesResolver: (n, t) => t.root
})({}), zt = Y(yn, {
  name: "MuiMenu",
  slot: "Paper",
  overridesResolver: (n, t) => t.paper
})({
  // specZ: The maximum height of a simple menu should be one or more rows less than the view
  // height. This ensures a tappable area outside of the simple menu with which to dismiss
  // the menu.
  maxHeight: "calc(100% - 96px)",
  // Add iOS momentum scrolling for iOS < 13.0
  WebkitOverflowScrolling: "touch"
}), Kt = Y(zn, {
  name: "MuiMenu",
  slot: "List",
  overridesResolver: (n, t) => t.list
})({
  // We disable the focus ring for mouse, touch and keyboard users.
  outline: 0
}), Tn = /* @__PURE__ */ u.forwardRef(function(t, o) {
  var r, a;
  const s = ue({
    props: t,
    name: "MuiMenu"
  }), {
    autoFocus: i = !0,
    children: f,
    className: h,
    disableAutoFocusItem: g = !1,
    MenuListProps: E = {},
    onClose: y,
    open: k,
    PaperProps: N = {},
    PopoverClasses: T,
    transitionDuration: l = "auto",
    TransitionProps: {
      onEntering: d
    } = {},
    variant: p = "selectedMenu",
    slots: S = {},
    slotProps: c = {}
  } = s, m = K(s.TransitionProps, At), v = K(s, Bt), w = _n(), b = R({}, s, {
    autoFocus: i,
    disableAutoFocusItem: g,
    MenuListProps: E,
    onEntering: d,
    PaperProps: N,
    transitionDuration: l,
    TransitionProps: m,
    variant: p
  }), D = Ht(b), A = i && !g && k, L = u.useRef(null), q = (M, V) => {
    L.current && L.current.adjustStyleForScrollbar(M, {
      direction: w ? "rtl" : "ltr"
    }), d && d(M, V);
  }, j = (M) => {
    M.key === "Tab" && (M.preventDefault(), y && y(M, "tabKeyDown"));
  };
  let B = -1;
  u.Children.map(f, (M, V) => {
    /* @__PURE__ */ u.isValidElement(M) && (process.env.NODE_ENV !== "production" && nn.isFragment(M) && console.error(["MUI: The Menu component doesn't accept a Fragment as a child.", "Consider providing an array instead."].join(`
`)), M.props.disabled || (p === "selectedMenu" && M.props.selected || B === -1) && (B = V));
  });
  const I = (r = S.paper) != null ? r : zt, P = (a = c.paper) != null ? a : N, x = ae({
    elementType: S.root,
    externalSlotProps: c.root,
    ownerState: b,
    className: [D.root, h]
  }), O = ae({
    elementType: I,
    externalSlotProps: P,
    ownerState: b,
    className: D.paper
  });
  return /* @__PURE__ */ C(Ut, R({
    onClose: y,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: w ? "right" : "left"
    },
    transformOrigin: w ? Vt : qt,
    slots: {
      paper: I,
      root: S.root
    },
    slotProps: {
      root: x,
      paper: O
    },
    open: k,
    ref: o,
    transitionDuration: l,
    TransitionProps: R({
      onEntering: q
    }, m),
    ownerState: b
  }, v, {
    classes: T,
    children: /* @__PURE__ */ C(Kt, R({
      onKeyDown: j,
      actions: L,
      autoFocus: i && (B === -1 || g),
      autoFocusItem: A,
      variant: p
    }, E, {
      className: Q(D.list, E.className),
      children: f
    }))
  }));
});
process.env.NODE_ENV !== "production" && (Tn.propTypes = {
  // ┌────────────────────────────── Warning ──────────────────────────────┐
  // │ These PropTypes are generated from the TypeScript type definitions. │
  // │    To update them, edit the d.ts file and run `pnpm proptypes`.     │
  // └─────────────────────────────────────────────────────────────────────┘
  /**
   * An HTML element, or a function that returns one.
   * It's used to set the position of the menu.
   */
  anchorEl: e.oneOfType([ye, e.func]),
  /**
   * If `true` (Default) will focus the `[role="menu"]` if no focusable child is found. Disabled
   * children are not focusable. If you set this prop to `false` focus will be placed
   * on the parent modal container. This has severe accessibility implications
   * and should only be considered if you manage focus otherwise.
   * @default true
   */
  autoFocus: e.bool,
  /**
   * Menu contents, normally `MenuItem`s.
   */
  children: e.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: e.object,
  /**
   * @ignore
   */
  className: e.string,
  /**
   * When opening the menu will not focus the active item but the `[role="menu"]`
   * unless `autoFocus` is also set to `false`. Not using the default means not
   * following WAI-ARIA authoring practices. Please be considerate about possible
   * accessibility implications.
   * @default false
   */
  disableAutoFocusItem: e.bool,
  /**
   * Props applied to the [`MenuList`](/material-ui/api/menu-list/) element.
   * @default {}
   */
  MenuListProps: e.object,
  /**
   * Callback fired when the component requests to be closed.
   *
   * @param {object} event The event source of the callback.
   * @param {string} reason Can be: `"escapeKeyDown"`, `"backdropClick"`, `"tabKeyDown"`.
   */
  onClose: e.func,
  /**
   * If `true`, the component is shown.
   */
  open: e.bool.isRequired,
  /**
   * @ignore
   */
  PaperProps: e.object,
  /**
   * `classes` prop applied to the [`Popover`](/material-ui/api/popover/) element.
   */
  PopoverClasses: e.object,
  /**
   * The extra props for the slot components.
   * You can override the existing props or add new ones.
   *
   * @default {}
   */
  slotProps: e.shape({
    paper: e.oneOfType([e.func, e.object]),
    root: e.oneOfType([e.func, e.object])
  }),
  /**
   * The components used for each slot inside.
   *
   * @default {}
   */
  slots: e.shape({
    paper: e.elementType,
    root: e.elementType
  }),
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: e.oneOfType([e.arrayOf(e.oneOfType([e.func, e.object, e.bool])), e.func, e.object]),
  /**
   * The length of the transition in `ms`, or 'auto'
   * @default 'auto'
   */
  transitionDuration: e.oneOfType([e.oneOf(["auto"]), e.number, e.shape({
    appear: e.number,
    enter: e.number,
    exit: e.number
  })]),
  /**
   * Props applied to the transition element.
   * By default, the element is based on this [`Transition`](https://reactcommunity.org/react-transition-group/transition/) component.
   * @default {}
   */
  TransitionProps: e.object,
  /**
   * The variant to use. Use `menu` to prevent selected items from impacting the initial focus.
   * @default 'selectedMenu'
   */
  variant: e.oneOf(["menu", "selectedMenu"])
});
const Wt = Tn, to = (n) => {
  const [t, o] = me.useState(null), [r, a] = me.useState(""), [s, i] = me.useState(() => {
    var l;
    if (n.multiple) {
      if (typeof ((l = n.value) == null ? void 0 : l[0]) == "string") {
        const d = n.options.filter((p) => typeof p == "string" ? n.value.includes(p) : n.value.join(",").includes(p.value));
        return d.length ? d : [n.value];
      }
      return n.value;
    }
    return [];
  }), f = !!t, h = (l) => {
    o(l.currentTarget);
  }, g = me.useMemo(() => {
    const l = r.trim().toLowerCase();
    return typeof n.options[0] == "string" && l.length > 0 ? n.options.filter((d) => d.toLowerCase().includes(l)) : typeof n.options[0] == "object" && l.length > 0 ? n.options.filter((d) => d.label.toLowerCase().includes(l)) : n.options;
  }, [n.options, r]), E = () => {
    o(null), a("");
  }, y = (l) => {
    if (n.multiple) {
      const d = s.some((S) => JSON.stringify(S) === JSON.stringify(l));
      let p = structuredClone(s);
      d ? (p = s.filter((S) => JSON.stringify(S) !== JSON.stringify(l)), i(p)) : (p.push(l), i(p)), n.onChange(p);
      return;
    } else
      n.onChange(l);
    E();
  }, k = me.useMemo(() => {
    const l = {};
    for (let d = 0; d < g.length; d++) {
      const p = g[d];
      typeof p != "string" && p.group && (l[p.group] ? l[p.group].push(p) : l[p.group] = [p]);
    }
    return l;
  }, [g]), N = qe(() => {
    if (typeof n.value == "string") {
      if (typeof n.options[0] == "object") {
        const l = n.options.find((d) => d.value === n.value);
        if (l)
          return l.label;
      }
      return n.value;
    }
    return n.value.label;
  }, [n.options, n.value]), T = qe(() => n.variant === "standard" ? _.value : n.variant === "outlined" ? `${_["value-outlined"]} ${_.value}` : n.variant === "filled" ? `${_["value-filled"]} ${_.value}` : n.variant === "none" ? _["value-none"] : _.value, [n.variant]);
  return /* @__PURE__ */ z(Pn, { children: [
    /* @__PURE__ */ C("label", { className: _.label, children: n.label }),
    /* @__PURE__ */ z("div", {
      className: T,
      style: n.style,
      onClick: h,
      // check if multiple is enabled and display chips if true else display the value
      children: [
        n.multiple ? /* @__PURE__ */ C("div", { className: _.multiChips, children: s.map((l) => /* @__PURE__ */ z("div", { className: _.chip, onClick: (d) => {
          d.stopPropagation();
        }, children: [
          n.startIcon ? /* @__PURE__ */ C("i", { className: _.icon, children: n.startIcon }) : null,
          typeof l == "string" ? l : l.label
        ] }, typeof l == "string" ? l : l.value)) }) : /* @__PURE__ */ z("span", { className: _.valueText, children: [
          n.startIcon ? /* @__PURE__ */ C("i", { className: _.icon, children: n.startIcon }) : null,
          N
        ] }),
        /* @__PURE__ */ C("svg", { width: 16, focusable: "false", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", children: /* @__PURE__ */ C("path", { d: "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" }) })
      ]
    }),
    /* @__PURE__ */ z(
      Wt,
      {
        open: f,
        anchorEl: t,
        onClose: E,
        className: _.menu,
        children: [
          /* @__PURE__ */ z("div", { className: _.input, children: [
            /* @__PURE__ */ C("input", { type: "text", value: r, onChange: (l) => a(l.target.value) }),
            /* @__PURE__ */ C("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", viewBox: "0 0 24 24", children: /* @__PURE__ */ C("g", { transform: "translate(24 0) scale(-1 1)", children: /* @__PURE__ */ C("path", { d: "m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3zM9.5 14q1.875 0 3.188-1.312T14 9.5q0-1.875-1.312-3.187T9.5 5Q7.625 5 6.313 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14" }) }) })
          ] }),
          /* @__PURE__ */ C("div", { className: _.options, children: Object.keys(k).length ? Object.keys(k).map((l) => /* @__PURE__ */ z("div", { children: [
            /* @__PURE__ */ C("div", { className: _.group, children: l }),
            k[l].map((d) => {
              const p = Array.isArray(n.value) ? s.some((S) => JSON.stringify(S) === JSON.stringify(d)) : JSON.stringify(d) === JSON.stringify(n.value);
              return n.renderOption ? n.renderOption(d, p) : /* @__PURE__ */ z(
                "div",
                {
                  className: `${_.option} ${p ? _.selected : ""} `,
                  style: { textTransform: "capitalize" },
                  onClick: () => y(d),
                  children: [
                    n.multiple && /* @__PURE__ */ C("input", { type: "checkbox", checked: p }),
                    n.startIcon ? /* @__PURE__ */ C("i", { className: _.icon, children: n.startIcon }) : null,
                    typeof d == "string" ? d : d.label
                  ]
                },
                typeof d == "string" ? d : d.value
              );
            })
          ] }, l)) : g.map((l) => {
            const d = Array.isArray(n.value) ? s.some((p) => JSON.stringify(p) === JSON.stringify(l)) : JSON.stringify(l) === JSON.stringify(n.value);
            return n.renderOption ? n.renderOption(l, d) : /* @__PURE__ */ z(
              "div",
              {
                className: `${_.option} ${d ? _.selected : ""}`,
                style: { textTransform: "capitalize" },
                onClick: () => y(l),
                children: [
                  n.multiple && /* @__PURE__ */ C("input", { type: "checkbox", checked: d }),
                  n.startIcon ? /* @__PURE__ */ C("i", { className: _.icon, children: n.startIcon }) : null,
                  typeof l == "string" ? l : l.label
                ]
              },
              typeof l == "string" ? l : l.value
            );
          }) })
        ]
      }
    )
  ] });
};
export {
  to as default
};
