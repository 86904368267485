import "./assets/style.css";
import { e as l, f as y, _ as P } from "./styled-832b6886.js";
import { a as g } from "./useTimeout-890b47bd.js";
const S = "exact-prop: ​";
function G(e) {
  return process.env.NODE_ENV === "production" ? e : l({}, e, {
    [S]: (s) => {
      const n = Object.keys(s).filter((t) => !e.hasOwnProperty(t));
      return n.length > 0 ? new Error(`The following props are not supported: ${n.map((t) => `\`${t}\``).join(", ")}. Please remove them.`) : null;
    }
  });
}
function x(e) {
  return typeof e == "string";
}
function N(e, s, n) {
  return e === void 0 || x(e) ? s : l({}, s, {
    ownerState: l({}, s.ownerState, n)
  });
}
function w(e, s = []) {
  if (e === void 0)
    return {};
  const n = {};
  return Object.keys(e).filter((t) => t.match(/^on[A-Z]/) && typeof e[t] == "function" && !s.includes(t)).forEach((t) => {
    n[t] = e[t];
  }), n;
}
function E(e, s, n) {
  return typeof e == "function" ? e(s, n) : e;
}
function h(e) {
  if (e === void 0)
    return {};
  const s = {};
  return Object.keys(e).filter((n) => !(n.match(/^on[A-Z]/) && typeof e[n] == "function")).forEach((n) => {
    s[n] = e[n];
  }), s;
}
function k(e) {
  const {
    getSlotProps: s,
    additionalProps: n,
    externalSlotProps: t,
    externalForwardedProps: o,
    className: a
  } = e;
  if (!s) {
    const v = y(n == null ? void 0 : n.className, a, o == null ? void 0 : o.className, t == null ? void 0 : t.className), m = l({}, n == null ? void 0 : n.style, o == null ? void 0 : o.style, t == null ? void 0 : t.style), f = l({}, n, o, t);
    return v.length > 0 && (f.className = v), Object.keys(m).length > 0 && (f.style = m), {
      props: f,
      internalRef: void 0
    };
  }
  const d = w(l({}, o, t)), i = h(t), c = h(o), r = s(d), u = y(r == null ? void 0 : r.className, n == null ? void 0 : n.className, a, o == null ? void 0 : o.className, t == null ? void 0 : t.className), p = l({}, r == null ? void 0 : r.style, n == null ? void 0 : n.style, o == null ? void 0 : o.style, t == null ? void 0 : t.style), _ = l({}, r, n, c, i);
  return u.length > 0 && (_.className = u), Object.keys(p).length > 0 && (_.style = p), {
    props: _,
    internalRef: r.ref
  };
}
const C = ["elementType", "externalSlotProps", "ownerState", "skipResolvingSlotProps"];
function I(e) {
  var s;
  const {
    elementType: n,
    externalSlotProps: t,
    ownerState: o,
    skipResolvingSlotProps: a = !1
  } = e, d = P(e, C), i = a ? {} : E(t, o), {
    props: c,
    internalRef: r
  } = k(l({}, d, {
    externalSlotProps: i
  })), u = g(r, i == null ? void 0 : i.ref, (s = e.additionalProps) == null ? void 0 : s.ref);
  return N(n, l({}, c, {
    ref: u
  }), o);
}
const O = "_value_1dse4_3", b = "_valueText_1dse4_42", H = "_label_1dse4_47", R = "_multiChips_1dse4_57", j = "_chip_1dse4_64", T = "_menu_1dse4_80", W = "_options_1dse4_84", $ = "_input_1dse4_101", A = "_error_1dse4_129", F = "_disabled_1dse4_133", Z = "_group_1dse4_142", D = "_option_1dse4_84", L = "_selected_1dse4_169", V = "_icon_1dse4_173", J = {
  value: O,
  "value-outlined": "_value-outlined_1dse4_19",
  "value-filled": "_value-filled_1dse4_27",
  "value-none": "_value-none_1dse4_35",
  valueText: b,
  label: H,
  multiChips: R,
  chip: j,
  menu: T,
  options: W,
  input: $,
  error: A,
  disabled: F,
  group: Z,
  option: D,
  selected: L,
  icon: V
};
export {
  G as a,
  w as e,
  x as i,
  J as s,
  I as u
};
