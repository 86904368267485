import F, { jsxs as i, jsx as e } from "react/jsx-runtime";
import { Box as l, Select as I, OutlinedInput as T, MenuItem as _, Divider as E, Typography as j } from "@mui/material";
import { useMemo as L, useState as m, useEffect as f } from "react";
import o from "moment-timezone";
import { styles as t } from "./components/DateTimeSelector/DateTimeSelect.style.js";
import { i as G } from "./styled-832b6886.js";
import { r as J } from "./createSvgIcon-4b11b20c.js";
import { DatePickerComponent as K } from "./components/DateTimeSelector/DatePickerComponent.js";
import { TimePickerComponent as Q } from "./components/DateTimeSelector/TimePickerComponent.js";
var D = {}, U = G;
Object.defineProperty(D, "__esModule", {
  value: !0
});
var b = D.default = void 0, X = U(J()), Z = F, ee = (0, X.default)(/* @__PURE__ */ (0, Z.jsx)("path", {
  d: "M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-7 5h5v5h-5z"
}), "EventOutlined");
b = D.default = ee;
const v = o.months(), te = Array.from({ length: 6 }, (s, c) => o().year() - c), re = o().year(), P = o().month(), ue = ({
  date: s,
  setDate: c,
  dateType: M,
  timeSlots: z,
  isTimeHidden: g,
  minTime: A,
  maxTime: H,
  startDate: V,
  endDate: k
}) => {
  const a = L(() => o.unix(s), [s]), [S, y] = m(a.month().toString()), [h, Y] = m(a.year().toString()), [u, q] = m(s), [d, w] = m(o.unix(s).format("h:mm A")), [O, C] = m(v.slice(0, P + 1)), B = g ? "MMM DD, YYYY" : "MMM DD, YYYY hh:mm A", N = (r) => {
    y(r.target.value);
  }, R = (r) => {
    Y(r.target.value);
  };
  return f(() => {
    parseInt(h) === re ? C(v.slice(0, P + 1)) : C(v);
  }, [h]), f(() => {
    y(a.month().toString()), Y(a.year().toString());
  }, [a]), f(() => {
    const [r, n] = d.split(" "), [p, W] = r.split(":").map(Number);
    let x = p;
    n === "PM" && p < 12 && (x += 12), n === "AM" && p === 12 && (x = 0);
    const $ = o.unix(u).hours(x).minutes(W).seconds(0).milliseconds(0);
    c($.unix());
  }, [u, d, c]), /* @__PURE__ */ i(l, { sx: t.dateTimeSelectorContainer, children: [
    /* @__PURE__ */ i(l, { sx: t.dateTimeSelectorsWrapper, children: [
      /* @__PURE__ */ e(
        I,
        {
          id: "month-select",
          value: S,
          onChange: N,
          displayEmpty: !0,
          classes: { select: "month-year-selector" },
          input: /* @__PURE__ */ e(T, { sx: t.monthYearSelectInput }),
          sx: t.monthYearSelectBox,
          children: O.map((r, n) => /* @__PURE__ */ e(_, { sx: t.monthYearMenuItem, value: n.toString(), children: r }, n))
        }
      ),
      /* @__PURE__ */ e(
        I,
        {
          id: "year-select",
          value: h,
          onChange: R,
          displayEmpty: !0,
          classes: { select: "month-year-selector" },
          input: /* @__PURE__ */ e(T, { sx: t.monthYearSelectInput }),
          sx: t.monthYearSelectBox,
          children: te.map((r, n) => /* @__PURE__ */ e(_, { sx: t.monthYearMenuItem, value: r.toString(), children: r }, n))
        }
      )
    ] }),
    /* @__PURE__ */ i(l, { sx: { display: "flex", alignItems: "center" }, children: [
      /* @__PURE__ */ e(
        K,
        {
          selectedDate: u,
          onDateChange: q,
          month: S,
          year: h,
          minTime: A,
          maxTime: H,
          startDate: V,
          endDate: k,
          dateType: M
        }
      ),
      !g && /* @__PURE__ */ e(
        Q,
        {
          selectedTime: d,
          onTimeChange: w,
          timeSlots: z
        }
      )
    ] }),
    /* @__PURE__ */ e(E, { sx: { borderColor: "#D6D6D6" } }),
    /* @__PURE__ */ i(l, { sx: t.datePickerContainer, children: [
      /* @__PURE__ */ i(l, { sx: t.datePickerContent, children: [
        /* @__PURE__ */ e(b, { sx: t.icon }),
        /* @__PURE__ */ e(j, { sx: { fontSize: "12px", fontWeight: 500 }, children: M === "start" ? "Start Date" : "End Date" })
      ] }),
      /* @__PURE__ */ e(E, { orientation: "vertical", sx: t.verticalDivider }),
      /* @__PURE__ */ e(j, { sx: t.formattedDateText, children: a.format(B) })
    ] })
  ] });
};
export {
  ue as D,
  b as d
};
